<template>
  <a-radio-group
    :value="checkedValue"
    :disabled="record.options.disabled"
    :name="record.options.model"
    :size="record.options.size"
    :buttonStyle="record.options.buttonStyle"
    @change="change"
  >
    <template v-for="(item, index) in options">
      <a-radio-button
        v-if="['outline','solid'].includes(record.options.buttonStyle)"
        :key="index"
        :disabled="item.disabled"
        :autoFocus="item.autoFocus"
        :checked="item.checked"
        :value="item[valueKey]"
      >
        {{item[labelKey]}}
      </a-radio-button>
      <a-radio
        v-else
        :key="index"
        :disabled="item.disabled"
        :autoFocus="item.autoFocus"
        :checked="item.checked"
        :value="item[valueKey]"
      >
        {{item[labelKey]}}
      </a-radio>
    </template>
  </a-radio-group>
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxRadio',
  mixins: [formEleMixin],
  computed: {
    checkedValue () {
      const { labelInValue } = this.record.options
      if (labelInValue) {
        return this.value.key
      }
      return this.value
    }
  },
  methods: {
    /**
     * 变化时回调函数
     * 要保存label的时候，返回去的值肯定是对象
     * 也不会把label和value分两个字段存，由页面自行处理
     * @param e
     */
    change (e) {
      const { labelInValue } = this.record.options
      const checkedValue = e.target.value
      if (labelInValue) {
        const value = this.formatValue(checkedValue).map(value => {
          const label = this.options.find(option => option[this.valueKey] === value)?.[this.labelKey]
          return { key: value, label: label }
        })[0]
        this.$emit('change', value, this.record.model)
      } else {
        this.$emit('change', checkedValue, this.record.model)
      }
    }
  }
}
</script>
